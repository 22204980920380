
import { computed, defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";

export default defineComponent({
  name: "RequestTotal",
  setup() {
    const store = useStore();
    onMounted(() => {
      setCurrentPageTitle("Хүсэлт");
    });
    const isu = computed(() => {
      return store.getters.getMbUser;
    });

    return {
      isu
    };
  },
  mounted() {
    if (this.isu) this.getRequests();
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      customerSearch: "",
      loadingtable: false,
      tableData: [],
      selectData: [
        {
          selectVal: "C",
          selectLabel: "Үүсгэсэн"
        },
        {
          selectVal: "R",
          selectLabel: "Зөвшөөрсөн"
        },
        {
          selectVal: "N",
          selectLabel: "Цуцалсан"
        },
        {
          selectVal: "A",
          selectLabel: "Баталсан"
        },
        {
          selectVal: "D",
          selectLabel: "Татгалзсан"
        },
        {
          selectVal: "*",
          selectLabel: "Бүгд"
        }
      ],
      selectVal: "*"
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
    },
    handleChange(value) {
      this.pageSize = value;
    },
    async getRequests() {
      this.loadingtable = true;
      await ApiService.get(`totalrequest`)
        .then(resp => {
          const results = resp.data;
          if (results.code === 0) {
            ElNotification({
              title: "Алдаа",
              message: results.error,
              type: "error",
              iconClass: "el-text-error"
            });
          } else if (results.code === 401) {
            ElNotification({
              title: "Анхааруулга",
              message: results.warning,
              type: "warning",
              iconClass: "el-text-warning"
            });
          } else {
            this.tableData = results.response.map(it => ({
              purpose: it.purpose,
              createddate: it.createddate,
              createdby: it.createdby,
              tablename: it.tablename,
              updatecolumn: it.updatecolumn,
              oldvalue: it.oldvalue,
              newvalue: it.newvalue,
              status: it.status,
              statusValue: it.statusValue,
              reviseddate: it.reviseddate,
              revisedby: it.revisedby,
              decideddate: it.decideddate,
              decidedby: it.decidedby,
              id: it.id,
              keyvalue: it.keyvalue,
              oldValueString: it.oldValueString,
              newValueString: it.newValueString,
              type: it.type,
              searchfield1: it.searchfield1,
              searchfield2: it.searchfield2
            }));
          }
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.loadingtable = false;
        });
    }
  }
});
